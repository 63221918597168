<template>
    <div class="content-height bg-app" :class="{'overflow-hidden': !scrollable}">
        <!-- Page Content -->
        <main class="w-screen h-full">
            <slot/>
        </main>
    </div>
</template>

<script setup lang="ts">

import {useGameStore} from "@/Store/Game/Game";
import {router} from "@inertiajs/vue3";
import {onMounted, type Ref, ref, watch} from "vue";
import {useOnline} from '@vueuse/core'

const tg = window.Telegram.WebApp;
const gameState = useGameStore();
gameState.platform = tg.platform

const expend = (): void => {
    if (!tg.isExpanded) {
        tg.expand()
    }
}

tg.ready()
// const height: Ref<number> = ref<number>(Math.round(tg.viewportHeight));

tg.onEvent("viewportChanged", () => {
    expend()
    // height.value = Math.round(tg.viewportHeight);
})
const online: Ref<boolean> = useOnline();

const props = defineProps<{
    disable_cache: boolean
}>();

watch(online, (v) => {
    if (!v) {
        tg.showAlert("No Internet Connection");
    }
}, {immediate: true})

onMounted(() => {
    expend()
    // const localScore = useStorage("score-size")
    // if (online.value && localScore) {
    //    const gameStore = useGameStore()
    //     gameStore.updateScore()
    // }
})

const clearCache = (): void => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister()
                }
            })
    }
}


if (window.disabled_cache) {
    clearCache()
}



const scrollable: Ref<boolean> = ref<boolean>(false)

router.on("navigate", () => {
    if ((route().current('game.frens') || route().current('game.questions')) && !tg.BackButton.isVisibile) {
        tg.BackButton.show();
        tg.BackButton.onClick(() => {
            router.visit(route("game.play"))
        })
        scrollable.value = true
    } else {
        tg.BackButton.hide();
        scrollable.value = false;
    }
})
</script>

<style>
.content-height {
    height: var(--tg-viewport-height);
}
</style>
