import {defineStore} from "pinia";
import ApiService from "@/Services/ApiService";
import {Ref, ref} from "vue";

export const useGameStore = defineStore("game", () => {

    const platform: Ref<string> = ref("");

    const login = (data: {
        data: string
        unsafe: string
    }) => {

        return ApiService.post("game.login", {
            ...data,
            platform: platform.value
        }, 3)
    }

    const syncScore = (score: number): Promise<any> => {
        return ApiService.post("game.score.sync", {
            score,
            platform: platform.value
        }, 3)
    }

    const updateScore = (score: number): Promise<any> => {
        return ApiService.post("game.score", {
            score,
            platform: platform.value
        }, 3)
    }

    const updatePower = (size: number): Promise<any> => {
        return ApiService.post("game.power", {
            size,
            platform: platform.value
        }, 2)
    }

    const fetchFrens = (page: number): Promise<any> => {
        return ApiService.get("game.frens", {
            page,
            platform: platform.value
        }, 3)
    }

    return {
        platform,
        syncScore,
        updateScore,
        updatePower,
        fetchFrens,
        login
    }
})
